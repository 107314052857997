import { styled } from 'styled-components';

const noClick = `
  cursor: auto;
  pointer-events: none;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const NavWrapper = styled.nav`
  width: 100%;
  margin: 0 auto;

  button {
    display: inline-flex;
    align-items: center;
    padding: 0 5px;
    cursor: pointer;
    color: ${props => props.theme.colors.accent};
    background: none;
    border: 0;
    font-size: 16px;
  }

  p {
    margin: 0;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      border-right: 1px solid ${props => props.theme.colors.black};

      .selected {
        color: ${props => props.theme.colors.black};
        ${noClick}
        text-decoration: underline;
      }

      &:last-child {
        border-right: 0;
      }

      .disabled {
        color: ${props => props.theme.colors.black};
        ${noClick}
      }

      .disabled-grey {
        color: ${props => props.theme.colors.darkGrey};
        ${noClick}
      }
    }
  }
`;

export const PaginationButton = styled.button`
  display: flex;
  justify-content: center;
  min-width: 44px;
  min-height: 44px;
  padding: 0;
  text-align: center;
`;
