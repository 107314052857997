import React, { useState, useEffect, useRef } from 'react';

import ImageNoAvailable from '../../../images/Image_not_available.png';
import ImagePlaceholder from './placeholders';

import type { Image as ImageProps } from './image.types';

import {
  MainImage, PaddingDiv, PictureWrapper
} from './image.styles';

/**
 *
 * @param {string} src Default url for the image.
 * @param {string} title Image alternative text.
 * @param {string} [src2x] Default url for display 2x pixels.
 * @param {import('./image.types').Source[]} [sources] Additional images sizes based on media queries.
 * @param {string} [imageWidth] Width property to be added to the <img>.
 * @param {string} [imageHeight] Height property to be added to the <img>.
 * @param {string | undefined} [paddingValue] Overrides padding-bottom value. Defaults to correct aspect ratio (height / width * 100 + '%')
 * @param {import('./image.types').LoadingType} [loading='lazy'] Loading of image ('lazy' | 'eager').
 * @param {import('./image.types').ObjectFit} [fit='cover'] How to fit the image into the container.
 * @param {string} [positionX='center'] How to position the image on the X axis.
 * @param {string} [positionY='center'] How to position the image on the Y axis.
 * @param {boolean} [parentIsLoading = false] Indicates if the parent is still loading
 * @param {boolean} [supportsUrlDimensions = true] If true, the image url supports dimensions for srcset
 *
 */

/**
 * How to use srcset/ sizes/ sources:
 * (https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images)
 * - If image displays at the same resolution on all devices, all you need is img with srcset (no sizes needed) with 1x, 1.5x, 2x image alternatives
 * - If we have the same image, displaying at different resolutions depending on device, you need img with srcset and sizes.
 *    sizes describes how the image will display at different screen widths e.g. `(min-width: 400px) 400px, 100%`
 *    srcset should give an array of different widths of the same image for browser to pick from e.g. `img-200.jpg 200w, img-400.jpg 400w, img-800.jpg 800w`
 * - If we have any of the above, but we want to use a different image depending on screen width, or offer alternate formats (like webp), source elements should be used
 *    for 3 different images, 2 source elements should be used (img can be one of them). Both source and img elements can use srcset and sizes as above.
 *    minWidth on source describes when that source's image should be used
 *    type describes type of image (e.g. "image/webp")
 *    imageWidth and imageHeight are the dimensions of the source image
 */

export const getDimension = (dim?:string): number => {
  if(!dim) return 0;
  const fullDimension = dim.replace('px', '');
  return Number(fullDimension);
};

export const Image = ({ image, title, srcSetWidths, sizes, sources, imageWidth, imageHeight, paddingValue, loading = 'lazy', fit = 'cover', positionX = 'center', positionY = 'center', testid = 'image', parentIsLoading = false, supportsUrlDimensions = true, className }:ImageProps):React.ReactElement => {
  const [isLoaded, setIsLoaded] = useState(false);
  const hasImage = !!image && !!image.baseUrl;
  const imageSrc = hasImage ? image.baseUrl as string + (image.path || '') : ImageNoAvailable;
  const imageTitle = hasImage ? title : 'Image not found';

  const imageRef = useRef<HTMLImageElement>(null);

  // Order lowest min width first - important for CSS media queries and creating source media value
  sources = sources?.sort((a, b) => getDimension(a?.minWidth) > getDimension(b?.minWidth) ? 1 : -1);

  const getImageURLWithWidth = (baseUrl: string, path: string, width:number):string => {
    return `${baseUrl}/_w${width}${path}`;
  };

  const convertWidthsIntoSrcSet = (baseUrl: string, path: string, arrayOfWidths: number[]) =>
    (arrayOfWidths.reduce((acc, currentWidth) => acc + ` ${getImageURLWithWidth(baseUrl, path, currentWidth)} ${currentWidth}w,`, '')).replace(/,$/, '').trim();

  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      setIsLoaded(true);
    }
  }, [imageRef]);

  if (parentIsLoading) {
    return (
      <PaddingDiv data-testid={`${testid}-data-loading`} imageWidth={getDimension(imageWidth)} imageHeight={getDimension(imageHeight)} paddingValue={paddingValue} sources={sources} className={className}>
        <ImagePlaceholder id={testid} width={getDimension(imageWidth)} height={getDimension(imageHeight)} sources={sources} />
      </PaddingDiv>
    );
  }

  return (
    <PaddingDiv data-testid={hasImage ? testid : `${testid}-not-found`} imageWidth={getDimension(imageWidth)} imageHeight={getDimension(imageHeight)} paddingValue={paddingValue} sources={sources} className={className}>
      <>
        {!isLoaded && (
          <ImagePlaceholder id={testid} width={getDimension(imageWidth)} height={getDimension(imageHeight)} sources={sources} />
        )}
        <PictureWrapper
          loaded={isLoaded}
          onLoad={() => setIsLoaded(true)}
          onError={() => setIsLoaded(false)}
        >
          {hasImage && !!sources && sources?.map((source, i) =>
            !!source.image.baseUrl && supportsUrlDimensions &&
              <source
                key={`${source.image.path}-${source.minWidth}`}
                srcSet={convertWidthsIntoSrcSet(source.image.baseUrl, source.image.path ?? '', (source.srcSetWidths ?? srcSetWidths) as number[])}
                sizes={source.sizes ?? sizes}
                { ...(source.type ? { type: source.type } : {}) }
                { ...(source.minWidth ? { media: `(min-width: ${source.minWidth})${(!!sources?.[i + 1] && !!sources?.[i + 1].minWidth ? ` AND (max-width: ${getDimension(sources?.[i + 1].minWidth) - 1}px)` : '')}` } : {}) }
              />
          )}
          <MainImage
            decoding='async'
            loading={loading}
            {...(hasImage && supportsUrlDimensions && srcSetWidths ? { srcSet: convertWidthsIntoSrcSet(image.baseUrl as string, image.path ?? '', srcSetWidths) } : {}) }
            {...(hasImage && supportsUrlDimensions && sizes ? { sizes } : {}) }
            src={imageSrc}
            alt={imageTitle}
            width={imageWidth}
            {...(imageHeight ? { height: imageHeight } : {})}
            objectFit={hasImage ? fit : 'contain'}
            positionX={positionX}
            positionY={positionY}
            ref={imageRef}
          />
        </PictureWrapper>
      </>
    </PaddingDiv>
  );
};
