import React, { useMemo } from 'react';
import { ProductImage, FreeGift } from '../../atoms';
import { ProductTile as ProductTileProps } from './productTile.types';
import ProductTileTitlePlaceholder from './placeholders/productTileTitlePlaceholder';
import ProductTileSubtitlePlaceholder from './placeholders/productTileSubtitlePlaceholder';
import ProductTilePricePlaceholder from './placeholders/productTilePricePlaceholder';
import ProductTileTextSubtitlePlaceholder from './placeholders/productTileTextSubtitlePlaceholder';
import ProductTileFlagStringPlaceholder from './placeholders/productTileFlagString';
import ProductTileStrikethroughPlaceholder from './placeholders/productTileStrikethroughPlaceholder';
import { getImage } from '../../../utils';

import {
  TileWrapperLink,
  TileContent,
  TitleAndSubtitle,
  TileTitle,
  TileImageWrapper,
  TilePrice,
  TileSubtitle,
  AdditionalText,
  TileStrikethrough,
  TileTextSubtitle,
  FlagString,
  GiftFlag,
  PriceText,
  LoadingFlagString,
} from './productTile.styles';

/**
 *
 * @param {() => void} onClick Callback on link click.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading.
 * @param {string} title Provides a product title.
 * @param {string} slug path to link to when clicked.
 * @param {{ type:string, baseUrl:string, path:string }[]} images Provides array of image objects.
 * @param {string} id Product tile id.
 * @param {string} priceText Price text.
 * @param {string} subtitle Subtitle text.
 *  @param {string} priceTextStrikethrough Price text stricken through.
 * @param {string} priceTextSubtitle Price subtitle text.
 * @param {string} flagString Provides flagged text.
 * @param {{ icon: import('../../../icons').IconsType, description: string}[]} giftFlag Provide flag of text and icon.
 * @param {boolean} [outOfStock = false] Indicates if out of stock
 *
 */

export const ProductTile = ({ title, slug, images, onClick, testid = 'product-tile', id, priceText, subtitle, priceTextStrikethrough, priceTextSubtitle, flagString, giftFlag, parentIsLoading, className = '', outOfStock = false }: ProductTileProps): React.ReactElement => {

  const handleClick = (event: React.MouseEvent | React.KeyboardEvent):void => {
    event.preventDefault();
    onClick();
  };

  const imageToUse = useMemo(() => getImage(images, 'main'), [images]);

  if(parentIsLoading) {
    return (
      <TileWrapperLink role="button" data-testid={testid} {...(id ? { id } : {})} className={className}>
        <TileImageWrapper>
          <ProductImage
            image={undefined}
            title={`${title} Cover`}
            imgWidth={160}
            parentIsLoading={parentIsLoading}
          />
        </TileImageWrapper>
        <TileContent>
          <TitleAndSubtitle>
            <TileTitle>
              <ProductTileTitlePlaceholder />
            </TileTitle>
            <TileSubtitle><ProductTileSubtitlePlaceholder /></TileSubtitle>
          </TitleAndSubtitle>
          <AdditionalText>
            <PriceText>
              <TilePrice><ProductTilePricePlaceholder /></TilePrice>
              <TileStrikethrough><ProductTileStrikethroughPlaceholder /></TileStrikethrough>
            </PriceText>
            <TileTextSubtitle><ProductTileTextSubtitlePlaceholder /></TileTextSubtitle>
            <LoadingFlagString><ProductTileFlagStringPlaceholder /></LoadingFlagString>
          </AdditionalText>
        </TileContent>
      </TileWrapperLink>
    );
  };

  return (
    <TileWrapperLink href={slug} role="button" onClick={handleClick} data-testid={testid} {...(id ? { id } : {})} className={className}>
      <TileImageWrapper>
        <ProductImage
          image={imageToUse}
          title={`${title} Cover`}
          imgWidth={160}
          parentIsLoading={parentIsLoading}
          sizes='(min-width: 438px) 185px, 146px'
          srcSetWidths={[100, 146, 185, 200, 292, 370, 400, 500]}
        />
      </TileImageWrapper>
      <TileContent>
        <TitleAndSubtitle>
          <TileTitle>
            {title}
          </TileTitle>
          {subtitle && <TileSubtitle>{subtitle}</TileSubtitle>}
        </TitleAndSubtitle>
        {outOfStock ? (
          <AdditionalText><PriceText><TilePrice>Out of stock</TilePrice></PriceText></AdditionalText>
        ) : (
          <AdditionalText>
            <PriceText>
              {priceText && <TilePrice>{priceText}</TilePrice>}
              {priceTextStrikethrough && <TileStrikethrough>{priceTextStrikethrough}</TileStrikethrough>}
            </PriceText>
            <TileTextSubtitle>{priceTextSubtitle}</TileTextSubtitle>
            {flagString && <FlagString>{flagString}</FlagString>}
            {!flagString && giftFlag &&
            <GiftFlag>
              <FreeGift icon={giftFlag.icon} description={giftFlag.description} />
            </GiftFlag>}
          </AdditionalText>
        )}
      </TileContent>
    </TileWrapperLink>
  );
};
