import React, { useEffect, useRef } from 'react';

import type { DropDownMenu as DropDownMenuProps } from './dropDownMenu.types';

import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';

import {
  MenuWrapper,
  BtnContentWrapper,
  MenuItemContainer,
  MenuItemsWrapper
} from './dropDownMenu.styles';

/**
 *
 * @param {React.ReactNode} [children]
 * @param {string} [title]
 * @param {DropDownMenuProps['direction']} [direction = 'left']
 * @param {string} [testid = 'dropdown-menu']
 * @param {string | undefined} [id]
 * @param {boolean} open State of dropdown being open
 * @param {(_val: boolean) => void} setOpen Function to set the value of `open`
 *
 */

export const DropDownMenu = ({
  children,
  title,
  direction = 'left',
  testid = 'dropdown-menu',
  id,
  open,
  setOpen,
  className
}:DropDownMenuProps):React.ReactElement => {
  const mainButton = useRef<HTMLDivElement>(null);
  const menuPanel = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e:React.MouseEvent | MouseEvent) => {
      if(open
        && menuPanel.current
        && !menuPanel.current.contains(e.target as Node)
        && mainButton.current
        && !mainButton.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };
    document.body.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.body.addEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const handleOpen = ():void => {
    setOpen(!open);
    if(menuPanel.current){
      const focusableElement = menuPanel.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const firstFocusable = focusableElement[0] as HTMLElement;
      if (firstFocusable) firstFocusable.focus();
    } else if(mainButton.current){
      mainButton.current.focus();
    }
  };

  return (
    <MenuWrapper
      ref={mainButton}
      role='region'
      aria-live='polite'
      aria-relevant='additions removals'
      data-testid={testid} {...(id ? { id } : {})}
      className={className}
    >
      <Button btnType='outlined' btnTheme='beigeWhite' onClick={handleOpen} testid={testid}>
        <BtnContentWrapper>
          <span>{title}</span><Icon glyph={open ? 'arrow_up' : 'arrow_down'} width='14' />
        </BtnContentWrapper>
      </Button>
      {open && (
        <MenuItemsWrapper ref={menuPanel} position={direction} data-testid={`${testid}-panel`}>
          <MenuItemContainer role='region' aria-live='polite' aria-relevant='additions removals'>
            {children}
          </MenuItemContainer>
        </MenuItemsWrapper>
      )}
    </MenuWrapper>
  );
};
