import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 40px 0;
  gap: 30px;
`;

export const TopBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
`;

export const Title = styled.h2`
  text-align: center;
  ${({ theme }) => theme.headings.xxLarge};
  font-weight: ${({ theme }) => theme.fontWeights.fw2};
`;

export const Arrows = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;

  svg {
    height: 23px;
    width: auto;
  }

  @media (min-width: 1024px) {
    display: flex;
  }

  @media (max-width: 1230px) {
    margin-right: 20px;
  }
`;

export const ArrowCircle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.red};
  cursor: pointer;
  background: none;

  &.disabled {
    border: 1px solid ${({ theme }) => theme.colors.lightGrey};

    svg path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }

`;

export const ScrollContainer = styled('div').withConfig({
  shouldForwardProp: props => !['flexGap'].includes(props)
})<{ flexGap: number }>`
  display: flex;
  flex-flow: row nowrap;
  gap: ${({ flexGap }) => `${flexGap}px`};
  width: 100%;
  overflow: auto;
  box-sizing: border-box;

  @media (max-width: 1230px) {
    padding: 0 ${({ flexGap }) => `${flexGap}px`};
  }

  @media (max-width: calc(${({ theme }) => theme.breakpoints.laptop} - 1px)) {
    scroll-snap-type: x mandatory;
  }

  > * {
    flex-shrink: 0;
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0;
  }
`;

const dotHeight = '15px';

export const PageDots = styled('div').withConfig({
  shouldForwardProp: props => !['activePage', 'gapReducer'].includes(props)
})<{ activePage: number, gapReducer: number }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: ${({ gapReducer }) => 15 - gapReducer}px;
  min-height: ${dotHeight};

  button:nth-of-type(${({ activePage }) => activePage + 1}) {
    width: 25px;
    background: ${({ theme }) => theme.colors.black};
  }
`;

export const Dot = styled.button`
  flex-shrink: 0;
  width: 15px;
  height: ${dotHeight};
  padding: 0;
  box-sizing: border-box;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.grey};
  border: 0;
  transition: 0.15s ease;
  cursor: pointer;
`;
