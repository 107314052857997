import React, { useState, useEffect, useContext } from 'react';
import PageWrapper from '../../components/PageWrapper/pageWrapper';
import Table from '../../components/Table/table';
import Loader from '../../components/Loader/loader';
import { mockData } from './mockData';

import { userContext } from 'src/context/userContext';

import { components } from 'src/lib/api/openapi';
import { apiClient } from 'src/lib/apiConfig';

export type Campaign = components['schemas']['Campaign-ace_campaign.read'];
type Error = {
  type?: string
  title?: string
  detail?: string
}

import {
  DashboardWrapper,
  PermissionError
} from './dashboard.styles';

const Dashboard = ():React.ReactElement => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [error, setError] = useState<Error>();

  const userDetails = useContext(userContext);

  const updateCampaigns = async (sorting?: string) => {
    const { data, error: apiError } = await apiClient.campaignsApi?.getCampaigns({
      token: userDetails.accessToken ?? '',
      ...(sorting && sorting !== 'default' ? { sorting } : {})
    });
    data?.push(...mockData); // DO NOT MERGE
    if (data) setCampaigns(data);
    if (apiError) {
      setError(apiError);
      console.error(apiError);
    };
  };

  useEffect(() => {
    updateCampaigns();
  }, []);

  return (
    <PageWrapper title='Dashboard'>
      <DashboardWrapper>
        {campaigns.length > 0 ? <Table campaigns={campaigns} updateCampaigns={updateCampaigns} /> : (error?.detail === 'Access Denied.' ? <PermissionError>You don&apos;t have the right permission to see this page. Please contact your manager to obtain the right permissions.</PermissionError> : <Loader />)}
      </DashboardWrapper>
    </PageWrapper>
  );
};

export default Dashboard;
