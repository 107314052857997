import { styled } from 'styled-components';
import { fontBasic } from '../../../styles/mixins';

export const ItemWrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 0 4px ${props => props.theme.colors.lightGrey};
`;

export const BasketItemWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 20px;
`;

export const ImageWrapper = styled.a`
  width: 55px;
  flex-shrink: 0;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    width: 75px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 100%;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    flex-direction: column;
    padding-left: 20px;
    justify-content: space-between;
  }
`;

export const SubscriptionInfo = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: ${props => props.theme.colors.black};

  h5 {
    font-weight: ${props => props.theme.fontWeights.fw2};
  }

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export const SubscriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export const SubscriptionType = styled.div`
  ${props => props.theme.headings.body}
  font-weight: ${props => props.theme.fontWeights.fw1};
  padding-left: 0;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    padding-left: 4px;
  }
`;

export const Description = styled.p`
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh1};
  font-weight: ${props => props.theme.fontWeights.fw1};
  padding: 10px 0 0;
  color: ${props => props.theme.colors.black};

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    padding-top: 10px;
  }
`;

export const BasketItemInfo = styled.div`
  width: 100%;
`;

export const PriceInfo = styled.p`
  font-size: ${props => props.theme.fontSizes.fs2};
  line-height: ${props => props.theme.lineHeights.lh1};
  font-weight: ${props => props.theme.fontWeights.fw2};
  padding-top: 0;
  color: ${props => props.theme.layout.primary};

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    padding-top: 0;
  }
`;

export const RemoveItem = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
`;

export const Total = styled.p`
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh1};
  font-weight: ${props => props.theme.fontWeights.fw1};
  color: ${props => props.theme.colors.darkGrey};
  display: none;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    display: block;
  }
`;

export const TotalContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    align-items: flex-end;
  }
`;

export const LinksWrappers = styled.div`
  padding-top: 10px;
  padding-left: 10px;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    padding-left: 20px;
  }

  svg {
    color: ${props => props.theme.colors.black};
    padding-right: 5px;
    padding-left: 15px;
    height: 16px;

    @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
      padding-left: 0;
    }
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.fontSizes.fs1};
    font-weight: ${props => props.theme.fontWeights.fw1};
    line-height: ${props => props.theme.lineHeights.lh1};
  }
`;

export const IssuePricing = styled.p`
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
  font-weight: ${props => props.theme.fontWeights.fw1};
  white-space: nowrap;
`;

export const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${props =>props.theme.breakpoints.tablet}) {
    margin-right: 20px;
    }
`;

export const DescriptionChildren = styled.div`
  max-width: 300px;
`;

export const ModifyContent = styled.div`
  display: flex;
  align-items: center;
`;

export const PromotionContent = styled.div`
  p {
    ${props => fontBasic(
    `clamp(${props.theme.fontSizes.fs0}, 3vw, ${props.theme.fontSizes.fs1})`,
    `clamp(${props.theme.lineHeights.lh0}, 3vw, ${props.theme.lineHeights.lh1})`,
  )}
    font-weight: ${props => props.theme.fontWeights.fw1};
  }

  svg {
    color: ${props => props.theme.layout.primary};
  }
`;

export const DeliveryContent = styled.p`
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh1};
  color: ${props => props.theme.layout.primary};
  padding-top: 5px;
`;

export const OriginalPrice = styled.div`
  font-size: ${props => props.theme.fontSizes.fs1};
  line-height: ${props => props.theme.lineHeights.lh1};
  color: ${props => props.theme.colors.darkGrey};
  text-decoration: line-through;
  padding-right: 5px;
`;

export const PriceContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    justify-content: center;
  }
`;
