import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../lib/utils';
import { SortingGroup, Pagination, DropDown } from '@arcade/component-library';
import type { Campaign } from '../../pages/Dashboaard/dashboard';
import { sortingOptions } from './sorting';

import {
  TableWrapper,
  TableRow,
  FilterWrapper,
  RowsPerPage,
  PaginationWrapper,
  PageCount,
} from './table.styles';

const giveIndexLimits = (page: number, itemsPerPage: number) => {
  const lowerLimit = ((page - 1) * itemsPerPage) + 1;
  const upperLimit = page * itemsPerPage;
  return [lowerLimit, upperLimit];
};

const Table = ({
  campaigns,
  updateCampaigns,
} : {
  campaigns: Campaign[],
  updateCampaigns: (_sort?: string) => Promise<void>
}): React.ReactElement => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const totalItems = campaigns.length;
  const limits = giveIndexLimits(page, itemsPerPage);

  const handlePaginationClick = (pageSelected: number | string) => {
    if (pageSelected !== 'all') {
      setPage(Number(pageSelected));
    }
  };

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(e.target.value));
  };

  const [sorting, setSorting] = useState('default');

  const onSortingChange = (id: string) => {
    setSorting(id);
  };

  const isRunning = (start:string, end?:string):boolean => {
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : null;
    const today = new Date();
    if (startDate.getTime() <= today.getTime() && ( !endDate || today.getTime() <= endDate.getTime())) return true;
    return false;
  };

  useEffect(() => {
    updateCampaigns(sorting);
  }, [sorting]);

  return (
    <>
      <FilterWrapper>
        <div>Search and Filters</div>
        <SortingGroup
          items={sortingOptions}
          selected={sorting}
          onChange={onSortingChange}
        />
      </FilterWrapper>
      <TableWrapper>
        <thead>
          <tr>
            <th>name</th>
            <th>Created by</th>
            <th>Last Edited By</th>
            <th>type</th>
            <th>status</th>
            <th>start Date</th>
            <th>end Date</th>
            <th>actions</th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {campaigns.filter((_, i) => i + 1 >= limits[0] && i + 1 <= limits[1]).map(col => {
            const campaignLive = isRunning(col.startDate as string, col.endDate);
            const status = col.status === 'published' && campaignLive ? 'running' : col.status;
            return (
              <TableRow key={col.id} status={status}>
                <td>{col.name}</td>
                <td>{col.createdBy}</td>
                <td>{col.editedBy}</td>
                <td>{col.type}</td>
                <td>{status}</td>
                <td>{col.startDate ? formatDateTime(col.startDate) : 'n/a'}</td>
                <td>{col.endDate ? formatDateTime(col.endDate) : 'n/a'}</td>
                <td>{col?.actions?.length || 0}</td>
                <td><Link to={`/campaign/${col.id}`}>View</Link></td>
              </TableRow>
            );
          })}
        </tbody>
      </TableWrapper>
      <FilterWrapper>
        <RowsPerPage>
          <p>Rows per page: </p>
          <DropDown
            id='campaign-table'
            testid='campaign-table'
            className='table-rowspp'
            options={[
              {
                value: '25',
                label: '25'
              },
              {
                value: '50',
                label: '50'
              },
              {
                value: '100',
                label: '100'
              }
            ]}
            selected={itemsPerPage.toString()}
            onChange={handleRowsPerPageChange}
          />
        </RowsPerPage>
        <PaginationWrapper>
          <Pagination
            pages={Math.ceil(totalItems / itemsPerPage)}
            pageSelected={page}
            onPageClick={handlePaginationClick}
          />
        </PaginationWrapper>
        <PageCount>{limits[0]}-{limits[1]} of {totalItems}</PageCount>
      </FilterWrapper>
    </>
  );
};

export default Table;
