const basicData = {
  id: '1',
  type: 'targeted',
  name: 'test campaign 456',
  description: '',
  status: ' draft',
  startDate: '2024-07-18T17:06:00+00:00',
  endDate: '2024-07-28T17:30:00+00:00',
  actions: [
    {
      mode: 'string',
      position: 'top',
      id: '95d759373e2c3e869b4c1bd67a64676d',
      targeting: {
        path: '*',
        pattern: '/test/*',
      },
      type: 'Banner',
    },
  ],
  channels: ['MAGAZINES_DIRECT_UK', 'MAGAZINES_DIRECT_EU'],
  sites: ['magazinesdirect', 'guitarworld'],
  promoCodes: [],
  createdBy: 'dave',
  editedBy: null,
};

export const mockData = [
  {
    ...basicData,
    id: '1'
  },
  {
    ...basicData,
    id: '2'
  },
  {
    ...basicData,
    id: '3'
  },
  {
    ...basicData,
    id: '4'
  },
  {
    ...basicData,
    id: '5'
  },
  {
    ...basicData,
    id: '6'
  },
  {
    ...basicData,
    id: '7'
  },
  {
    ...basicData,
    id: '8'
  },
  {
    ...basicData,
    id: '9'
  },
  {
    ...basicData,
    id: '10'
  },
  {
    ...basicData,
    id: '12'
  },
  {
    ...basicData,
    id: '13'
  },
  {
    ...basicData,
    id: '14'
  },
  {
    ...basicData,
    id: '15'
  },
  {
    ...basicData,
    id: '16'
  },
  {
    ...basicData,
    id: '17'
  },
  {
    ...basicData,
    id: '18'
  },
  {
    ...basicData,
    id: '19'
  },
  {
    ...basicData,
    id: '20'
  },
  {
    ...basicData,
    id: '21'
  },
  {
    ...basicData,
    id: '22'
  },
  {
    ...basicData,
    id: '23'
  },
  {
    ...basicData,
    id: '24'
  },
  {
    ...basicData,
    id: '25'
  },
  {
    ...basicData,
    id: '26'
  },
  {
    ...basicData,
    id: '27'
  },
  {
    ...basicData,
    id: '28'
  },
  {
    ...basicData,
    id: '29'
  },
  {
    ...basicData,
    id: '30'
  },
  {
    ...basicData,
    id: '31'
  },
  {
    ...basicData,
    id: '32'
  },
  {
    ...basicData,
    id: '33'
  },
  {
    ...basicData,
    id: '34'
  },
  {
    ...basicData,
    id: '35'
  },
  {
    ...basicData,
    id: '36'
  },
  {
    ...basicData,
    id: '37'
  },
  {
    ...basicData,
    id: '38'
  },
  {
    ...basicData,
    id: '39'
  },
  {
    ...basicData,
    id: '40'
  },
  {
    ...basicData,
    id: '41'
  },
  {
    ...basicData,
    id: '42'
  },
  {
    ...basicData,
    id: '43'
  },
  {
    ...basicData,
    id: '44'
  },
  {
    ...basicData,
    id: '45'
  },
  {
    ...basicData,
    id: '46'
  },
  {
    ...basicData,
    id: '47'
  },
  {
    ...basicData,
    id: '48'
  },
  {
    ...basicData,
    id: '49'
  },
  {
    ...basicData,
    id: '50'
  },
  {
    ...basicData,
    id: '51'
  },
  {
    ...basicData,
    id: '52'
  },
  {
    ...basicData,
    id: '53'
  },
  {
    ...basicData,
    id: '54'
  },
  {
    ...basicData,
    id: '55'
  },
  {
    ...basicData,
    id: '56'
  },
  {
    ...basicData,
    id: '57'
  },
  {
    ...basicData,
    id: '58'
  },
  {
    ...basicData,
    id: '59'
  }
];
