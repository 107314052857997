import { styled } from 'styled-components';

export const RowColumnsWrapper = styled('div').withConfig({
  shouldForwardProp: props =>
    !['rowColSpan'].includes(props)
})<{rowColSpan: number}>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    grid-template-columns: repeat(${props => props.rowColSpan}, 1fr);
  }
`;
