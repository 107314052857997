import { styled } from 'styled-components';

export const LayoutGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  width: 330px;
  box-sizing: border-box;
  flex-shrink: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 376px;
  }

  @media (max-width: calc(${({ theme }) => theme.breakpoints.laptop} - 1px)) {
    scroll-snap-align: start;
  }
`;
