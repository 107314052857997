import React, { forwardRef, ForwardedRef, useState, useEffect, useRef, useMemo } from 'react';
import { executeOnKeyDown } from '../../../utils/keyboardEvents';
import { Icon } from '../Icon';
import type { DropDown as DropDownProps } from './dropdown.types';
import debounce from 'lodash/debounce';

import { DropdownTextPlaceholder } from './placeholders/dropdownPlaceholders';

import {
  SelectWrapper,
  SelectHeader,
  HeaderText,
  SelectUL,
  SelectLi,
  Select,
  InputCheck,
  FilterInput,
  ClearButton,
  NoMatchingOptions
} from './dropdown.styles';

/**
 * Dropdown
 *
 * @extends {React.ComponentPropsWithRef<'select'>}
 * @param {string} id Dropdown id.
 * @param {string} [name] Name to be assigned to the <select> element.
 * @param {string} [label] Label to be add in-line with the value selected.
 * @param {{ value: string, label: string }[]} options The options passed to the <select>.
 * @param {string} [selected] Selected option.
 * @param {string} [placeholder] Option to pass when nothing is selected.
 * @param {(event: ChangeEvent<HTMLSelectElement>) => void} [onChange] Event trigger when a selection is made.
 * @param {boolean} [searchable=false] Input will be searchable.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading data
 */

export const DropDown= forwardRef((props:DropDownProps, ref: ForwardedRef<HTMLSelectElement>) => {
  const [selectedValue, setSelectedValue] = useState<string>(props.selected || '');
  const [headerHeight, setHeaderHeight] = useState<number>(44);
  const { name, options, testid, placeholder = '', label, id, className } = props;
  const [filteredOptions, setFilteredOptions] = useState(options);
  const selectDropdownRef = useRef<HTMLDivElement>(null);
  const filterInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if(options) setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    const [selectedOption] = options.filter(opt => opt.value === props.selected);
    if (selectedOption || (!props?.selected || props?.selected === '')) setSelectedValue(props.selected || '');
  }, [props?.selected]);

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = event.target;
    setSelectedValue(value);
    const { onChange } = props;
    if (onChange) {
      onChange(event);
    }
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const close = (e: any) => {
      if (selectDropdownRef.current && !selectDropdownRef.current.contains(e.target as Node)) return setIsOpen(false);
      if (e.key === 'Escape') return setIsOpen(false);
    };
    document.body.addEventListener('keydown', close);
    document.body.addEventListener('mousedown', close);
    return () => {
      document.body.removeEventListener('keydown', close);
      document.body.removeEventListener('mousedown', close);
    };
  }, [isOpen]);

  useEffect(() => {
    if (selectDropdownRef.current) {
      setHeaderHeight(selectDropdownRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    if (selectDropdownRef.current) {
      setHeaderHeight(selectDropdownRef.current.clientHeight);
    }
  }, [selectedValue]);

  const handleOptionSelect = (value: string): void => {
    setSelectedValue(value);

    const { onChange } = props;

    if (onChange) {
      onChange({ target: { value } } as React.ChangeEvent<HTMLSelectElement>);
    }

    setIsOpen(false);

    if (filterInputRef.current) {
      filterInputRef.current.value = '';
    }
  };

  const getLabelFromValue = ():string => {
    const [selectedOption] = options.filter(opt => opt.value === selectedValue);
    if (selectedOption){
      if(label && !props.searchable ) {
        return `<label>${label}</label> <span>${selectedOption.label}</span>`;
      }
      return selectedOption.label;
    }
    return placeholder;
  };

  const handleSelectToggle = (): void => {
    setIsOpen(!isOpen);

    if (props.disabled === true) return setIsOpen(false);
  };

  useEffect(() => {
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, []);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const searchValue = event.target.value;
    const newOptions = options.filter(filterOption => filterOption.label.toLowerCase().includes(searchValue.toLowerCase()));

    setFilteredOptions(newOptions);
  };

  const debouncedHandleSearch = useMemo(
    () => debounce(handleChange, 300),
    [handleChange]
  );

  const onClear = (event: React.MouseEvent) => {
    event.preventDefault();
    if (filterInputRef.current) {
      filterInputRef.current.value = '';
      setFilteredOptions(options);
    }
  };

  if (props.parentIsLoading) {
    return (
      <SelectWrapper ref={selectDropdownRef} data-testid={testid} id={id} className={className}>
        <SelectHeader
          isOpen={false}
          parentIsLoading
        >
          <DropdownTextPlaceholder />
          <Icon width='14px' glyph={`arrow_${isOpen ? 'up' : 'down'}`} />
        </SelectHeader>
      </SelectWrapper>
    );
  }

  return (
    <SelectWrapper ref={selectDropdownRef} data-testid={testid} id={id} className={className}>
      {/* Hidden native select element */}
      <Select
        id="sel"
        data-testid={`dropdown-${testid}`}
        ref={ref}
        name={name}
        value={selectedValue}
        onChange={onChange}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
      </Select>
      {/* Replacement select element */}
      {props.searchable ? (
        <InputCheck>
          <FilterInput
            data-testid='filter-input'
            disabled={props.disabled}
            error={props.error}
            isOpen={isOpen}
            tabIndex={0}
            role="presentation"
            onKeyDown={(e:React.KeyboardEvent<HTMLInputElement>) => executeOnKeyDown(e) && handleSelectToggle}
            onClick={handleSelectToggle}
            className="select-option"
            placeholder={getLabelFromValue()}
            onChange={debouncedHandleSearch}
            searchable={props.searchable}
            ref={filterInputRef}
          />
          {filterInputRef.current?.value && <ClearButton onClick={onClear} data-testid='clear-input-button'>
            <Icon glyph='close' width='14px' />
          </ClearButton>}
          <Icon glyph={`arrow_${isOpen ? 'up' : 'down'}`} width='14px' />
        </InputCheck>
      ) : (
        <SelectHeader
          data-testid="select-custom"
          disabled={props.disabled}
          error={props.error}
          isOpen={isOpen}
          tabIndex={0}
          role="presentation"
          onKeyDown={(e:React.KeyboardEvent<HTMLDivElement>) => executeOnKeyDown(e) && handleSelectToggle}
          onClick={handleSelectToggle}
          className="select-option"
        >
          <HeaderText dangerouslySetInnerHTML={{ __html: getLabelFromValue() }} />
          <Icon glyph={`arrow_${isOpen ? 'up' : 'down'}`} />
        </SelectHeader>
      )}

      {isOpen && (
        <SelectUL
          hHeight={headerHeight}
          error={props.error}
          isOpen={isOpen}
          aria-roledescription='select option'
          aria-required
        >
          {filteredOptions.map(opt => (
            <SelectLi
              tabIndex={0}
              key={opt.value}
              role="list"
              onClick={() => handleOptionSelect(opt.value)}
              onKeyDown={(e:React.KeyboardEvent<HTMLLIElement>) => executeOnKeyDown(e) && handleOptionSelect(opt.value)}
            >
              {opt.label}
            </SelectLi>
          ))}
          {filteredOptions.length === 0 && <NoMatchingOptions> No matches</NoMatchingOptions>}
        </SelectUL>
      )}
    </SelectWrapper>
  );
});
