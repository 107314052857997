import { styled } from 'styled-components';
import { Button } from '../../atoms/Button/button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
`;

export const Title = styled.h2`
  margin-bottom: 30px;
  text-align: center;
  ${props => props.theme.headings.xxLarge};
  font-weight: ${({ theme }) => theme.fontWeights.fw2};
`;

/**
 * This extra container is needed for ProductContainer to have justify-content: center and still see all items on small widths (weird flex bug)
 * justify-content: center is desired as we can have small number of items which looks odd if not centred
 */
export const ScrollContainer = styled.div`
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
  padding-bottom: 10px;

  @media (max-width: calc(${({ theme }) => theme.breakpoints.laptop} - 1px)) {
    scroll-snap-type: x mandatory;
  }
`;

// min-width: fit-content ensures all items are included even when flex wants to cut the first one off on small widths (weird flex bug)
export const ProductContainer = styled.div`
  min-width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .product-tile {
    min-width: 159px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    gap: 20px;
  }

  @media (max-width: 1230px) {
    padding: 0 15px;
  }

  @media (max-width: 1230px and min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 0 20px;
  }

  @media (max-width: calc(${({ theme }) => theme.breakpoints.laptop} - 1px)) {
    .product-tile {
      scroll-snap-align: start;
    }
  }
`;

export const ButtonAnchor = styled.a`
  text-decoration: none;
`;

export const ButtonStyled = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes.fs1};
  line-height: ${({ theme }) => theme.lineHeights.lh1};

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fontSizes.fs2};
    line-height: ${({ theme }) => theme.lineHeights.lh2};
  }
`;
